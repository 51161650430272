import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateArticles } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import  Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class NewsDesUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
            Title: this.props.record.Title,
            Heading:this.props.record.Heading,
            Description: this.props.record.Description,
            NewsLink : this.props.record.NewsLink,
            Description1: this.props.record.Description1,
            NewsLink1 : this.props.record.NewsLink1,
            Description2: this.props.record.Description2,
            NewsLink2: this.props.record.NewsLink2,
            Description3: this.props.record.Description3,
            NewsLink3 : this.props.record.NewsLink3,
            VimeoLink:this.props.record.VimeoLink,
            VimeoLink1:this.props.record.VimeoLink1,
            VimeoLink2:this.props.record.VimeoLink2,
            VimeoLink3:this.props.record.VimeoLink3,
            file:{},
            errors: {},
           };
    }
    componentDidMount(props){
        console.log("data>>>>>>> :",this.props.record)
        
    }
    
   
    componentWillReceiveProps(nextProps) {
         console.log("next Props>>>>",nextProps.record)
        if (nextProps.record) {
            this.setState({
                id: nextProps?.record?._id,
                Title: nextProps?.record?.Title,
               Heading:nextProps?.record?.Heading,
               Description: nextProps?.record?.Description,
               NewsLink :nextProps?.record?.NewsLink,
               Description1: nextProps?.record?.Description1,
               NewsLink1 :nextProps?.record?.NewsLink1,
               Description2: nextProps?.record?.Description2,
               NewsLink2 :nextProps?.record?.NewsLink2,
               Description3: nextProps?.record?.Description3,
               NewsLink3 :nextProps?.record?.NewsLink3,
               VimeoLink:nextProps?.record?.VimeoLink,
               VimeoLink1:nextProps?.record?.VimeoLink1,
               VimeoLink2:nextProps?.record?.VimeoLink2,
               VimeoLink3:nextProps?.record?.VimeoLink3,
                file: nextProps?.record?.file,
                logoURI : nextProps?.record?.logoURI
            })
          
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {
           
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
            $('#update-articles-modal').modal('hide');
        
    }

   
    onChange = e => {
       
        
        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
       if (e.target.id === 'Heading') {
          this.setState({ Heading: e.target.value });
        }
        // if (e.target.id === 'Description') {
        //     this.setState({ Description: e.target.value });
        // }
        if (e.target.id === 'NewsLink') {
            this.setState({ NewsLink: e.target.value });
        }
        if (e.target.id === 'VimeoLink') {
            this.setState({ VimeoLink: e.target.value });
        }
        // if (e.target.id === 'Description1') {
        //     this.setState({ Description1: e.target.value });
        // }
        if (e.target.id === 'NewsLink1') {
            this.setState({ NewsLink1: e.target.value });
        }
        if (e.target.id === 'VimeoLink1') {
            this.setState({ VimeoLink1: e.target.value });
        }
        // if (e.target.id === 'Description2') {
        //     this.setState({ Description2: e.target.value });
        // }
        if (e.target.id === 'NewsLink2') {
            this.setState({ NewsLink2: e.target.value });
        }
        if (e.target.id === 'VimeoLink2') {
            this.setState({ VimeoLink2: e.target.value });
        }
        // if (e.target.id === 'Description3') {
        //     this.setState({ Description3: e.target.value });
        // }
        if (e.target.id === 'NewsLink3') {
            this.setState({ NewsLink3: e.target.value });
        }
      
        if (e.target.id === 'VimeoLink3') {
            this.setState({ VimeoLink3: e.target.value });
        }
      
        

        

        

    };

     handleChange = (value) => {
        this.setState({ Description:value });       
       }
       handleChange1 = (value) => {
        this.setState({ Description1:value });       
       }
       handleChange2 = (value) => {
        this.setState({ Description2:value });       
       }
       handleChange3 = (value) => {
        this.setState({ Description3:value });       
       }
    onFileChange = event => {
        console.log("filesssssssss", event.target.files[0])
        var name = event.target.value;
           // Update the state
           this.setState({ file: event.target.files[0]});
           this.setState({ imagepath: name});
          
         };


    // onFileChange = async(event) => {
    //     var name = event.target.value;
    //     this.setState({ file: event.target.files[0]});
    //     this.setState({ imagepath: name});
    //        var width;
    //        var height;
    //        var img = new Image()
    //        img.src = window.URL.createObjectURL(event.target.files[0])
    //        img.onload = () => {
    //         width = img.width;
    //         height = img.height;
      
           
        
    //        if ( Number(width) != Number(1600) && Number(height) != Number(675)){
    //         toast.error(`Image Height and width should be ${675} pixel and ${1600} pixel`,{
    //             style: {
    //             minWidth: '300px',
    //             minHeight: '55px'
    //             }
    //         }) 

    //         this.setState({ file: ""});
    //               this.setState({ imagepath: ""});
             
    //       }
          
    //         sleep(2000) 
           
    
    //        }
    //      };
   
    onFormUpdate = e => {
        e.preventDefault();
        const updateForm = {
            _id: this.state.id,
            Title: this.state.Title,
            Heading:this.state.Heading,
            Description:this.state.Description,
            NewsLink : this.state.NewsLink,
            VimeoLink:this.state.VimeoLink,
            Description1:this.state.Description1,
            NewsLink1 : this.state.NewsLink1,
            VimeoLink1:this.state.VimeoLink1,
            Description2:this.state.Description2,
            NewsLink2 : this.state.NewsLink2,
            VimeoLink2:this.state.VimeoLink2,
            Description3:this.state.Description3,
            NewsLink3 : this.state.NewsLink3,
            VimeoLink3:this.state.VimeoLink3,
            file: this.state.file
 
        };
        console.log("from",updateForm);
        this.props.updateArticles(updateForm);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
            <div className="modal fade" id="update-articles-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Latest Articles</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate id="update-form">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.id}
                                    id="form-update-id"
                                    type="text"
                                    required
                                    className="d-none"/>
                              
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="tokenAddresses">Title</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.Title}
                                            error={errors.Title}
                                            id="Title"
                                            type="text"
                                            required
                                            className={classnames("form-control", {
                                                invalid: errors.Title
                                            })}
                                        />
                                        <span className="text-danger">{errors.Title}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="quoteTokenAdresses">Heading</label>
                                    </div>
                                    <div className="col-md-9">
                                        <textarea
                                            onChange={this.onChange}
                                            value={this.state.Heading}
                                            error={errors.Heading}
                                            id="Heading"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.Heading
                                            })}
                                        />
                                        <span className="text-danger">{errors.Heading}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Description 1</label>
                                    </div>
                                    <div className="col-md-9">
                                    <ReactQuill                                               
                                    
                                           onChange={this.handleChange}
                                            value={this.state.Description}
                                            error={errors.Description}
                                            id="Description"
                                            theme="snow" 
                                               type="text" 
                                            // className={classnames("form-control", {
                                            //     invalid: errors.Description
                                            // })}
                                        />
                                        <span className="text-danger">{errors.Description}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Youtube Link 1</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.NewsLink}
                                            error={errors.NewsLink}
                                            id="NewsLink"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.NewsLink
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.NewsLink}</span>
                                    </div>
                                   
                                   
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Vimeo Link 1</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.VimeoLink}
                                            error={errors.VimeoLink}
                                            id="VimeoLink"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.VimeoLink
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.VimeoLink}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Description 2</label>
                                    </div>
                                    <div className="col-md-9">
                                    <ReactQuill                                               
                                    
                                           onChange={this.handleChange1}
                                            value={this.state.Description1}
                                            error={errors.Description1}
                                            id="Description1"
                                            theme="snow" 
                                               type="text" 
                                            // className={classnames("form-control", {
                                            //     invalid: errors.Description
                                            // })}
                                        />
                                        <span className="text-danger">{errors.Description1}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Youtube Link2</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.NewsLink1}
                                            error={errors.NewsLink1}
                                            id="NewsLink1"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.NewsLink1
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.NewsLink1}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Vimeo Link 2</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.VimeoLink1}
                                            error={errors.VimeoLink1}
                                            id="VimeoLink1"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.VimeoLink1
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.VimeoLink1}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Description 3</label>
                                    </div>
                                    <div className="col-md-9">
                                    <ReactQuill                                               
                                    
                                           onChange={this.handleChange2}
                                            value={this.state.Description2}
                                            error={errors.Description2}
                                            id="Description2"
                                            theme="snow" 
                                               type="text" 
                                            // className={classnames("form-control", {
                                            //     invalid: errors.Description
                                            // })}
                                        />
                                        <span className="text-danger">{errors.Description2}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Youtube Link3</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.NewsLink2}
                                            error={errors.NewsLink2}
                                            id="NewsLink2"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.NewsLink2
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.NewsLink2}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Vimeo Link 2</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.VimeoLink2}
                                            error={errors.VimeoLink2}
                                            id="VimeoLink2"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.VimeoLink2
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.VimeoLink2}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Description 4</label>
                                    </div>
                                    <div className="col-md-9">
                                    <ReactQuill                                               
                                    
                                           onChange={this.handleChange3}
                                            value={this.state.Description3}
                                            error={errors.Description3}
                                            id="Description3"
                                            theme="snow" 
                                               type="text" 
                                            // className={classnames("form-control", {
                                            //     invalid: errors.Description
                                            // })}
                                        />
                                        <span className="text-danger">{errors.Description3}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Youtube Link4</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.NewsLink3}
                                            error={errors.NewsLink3}
                                            id="NewsLink3"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.NewsLink3
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.NewsLink3}</span>
                                    </div>
                                   
                                   
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="depositFee">Vimeo Link 4</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                          
                                           onChange={this.onChange}
                                            value={this.state.VimeoLink3}
                                            error={errors.VimeoLink3}
                                            id="VimeoLink3"
                                           
                                            className={classnames("form-control", {
                                                invalid: errors.VimeoLink3
                                            })}
                                        ></input>
                                        <span className="text-danger">{errors.VimeoLink3}</span>
                                    </div>
                                   
                                   
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="logoURI">Update Image</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            autoComplete={''}
                                            onChange={this.onFileChange}
                                            value={this.state.imagepath}
                                            error={errors.logoURI}
                                            id="logoURI"
                                            type="file"
                                            className={classnames("form-control", {
                                                invalid: errors.logoURI
                                            })}
                                        />
                                        <span className="text-danger">{errors.logoURI}</span>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <a
                                form="update-form"
                               
                                className="btn btn-primary" onClick={this.onFormUpdate}>
                                Update
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

NewsDesUpdateModal.propTypes = {
    updateForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateArticles }
)(withRouter(NewsDesUpdateModal));
