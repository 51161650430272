import React from 'react'
import classnames from "classnames";
import PropTypes, { array } from "prop-types";
import { connect, useDispatch } from "react-redux";
import { updateForm, updateLandForm } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { FORMLAND_UPDATE } from '../../actions/types';
import $ from 'jquery';
import Web3 from 'web3';


import 'react-toastify/dist/ReactToastify.css';
import backurl from '../../lib/config';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class FormHomeUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        { console.log("props_data", this.props.record) }
        this.state = {
            id: this.props.record._id,
            Identifier: this.props.identifier,
            Title: this.props.record.Title,
            Description: this.props.record.Description,
            // content: this.props.record.content,
            Content: [],
            file: {},
            errors: {},
            Files: [],
            ImageURI: this.props.record.ImageURI,
            status: false
        };
    }


    componentDidMount(props) {
        console.log("data>>>>>>> :", this.props.status)
        this.setState({ status: this.props.status })

    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.record) {
            console.log("nextProps.record", nextProps.record, nextProps.auth)
            this.setState({
                id: nextProps.record._id,
                Identifier: nextProps.record.Identifier,
                Title: nextProps.record.Title,
                Description: nextProps.record.Description,
                Content: nextProps.record.ContentArray,
                file: nextProps.record.file,
                ImageURI: nextProps.record.ImageURI
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {
            console.log("this.sattussss", this.state.status)
            if (this.state.status == true) {
                toast(nextProps.auth.form.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.setState({ status : false})
            }


        }
        $('#update-form-modal').modal('hide');
        $('#modal')



    }

    onChange = e => {


        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }

    };



    onChange1 = (e, ind) => {

        console.log("enter those", e.target.id, e.target.value, ind)
        let data1 = this.state.Content;
        let index = ind;
        let arr = []
        data1.map((val, i) => {
            if (index == i) {
                console.log("value_data", val)
                let spirit = { ...val, ...{ [e.target.id]: e.target.value } };
                console.log("spiritdata", spirit)
                arr.push(spirit)
            } else {
                arr.push(val)
            }
        })

        this.setState({ Content: arr })
    }


    handleFile = (e) => {

        var file = e.target.files[0];
        let fileName = file.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "webp" || extFile == "png") {
            this.setState({ file: file })
        } else {
            toast("Allowed Only JPG, WEBP, PNG File", {
                position: toast.POSITION.TOP_CENTER
            });   
        }

    }

    onFileChange = (e, ind) => {

        var file = e.target.files[0];
        let index = ind
        let arr = [];

        let data1 = this.state.Files;
        let fileName = file.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "webp" || extFile == "png" || extFile == "gif") {
            if (data1.length > 0) {
                if (data1.some((val) => { return (val.index == index) })) {
                    data1.map((val) => {

                        if (val.index == index) {
                            let spirit = { ...val, ...{ ImageURI: e.target.files[0], index: ind } }
                            arr.push(spirit)
                            console.log("enter 2222 jjj", spirit)
                        } else {
                            arr.push(val)
                            console.log("enter 3333 jjj", val)
                        }
                    })
                } else {
                    let spirit = { ImageURI: e.target.files[0], index: ind }
                    console.log("enter 4444 jjj", spirit)
                    arr.push(...data1, ...[spirit])
                }
            } else {

                let spirit = { ...data1, ...{ ImageURI: e.target.files[0], index: ind } }
                console.log("enter 5555 jjj", spirit)
                arr.push(spirit)
            }
            this.setState({ Files: arr })
        } else {
            toast("Allowed Only JPG, WEBP, PNG File", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        console.log("datasdfasdfas jjj", arr)

        console.log("array_data", arr)
       


        let data2 = this.state.Content;
        let arr1 = []
        if(extFile == "jpg" || extFile == "webp" || extFile == "png" || extFile == "gif"){
            data2.map((val, i) => {
                if (index == i) {
                    let spirit = { ...val, ...{ ImageURI: "" } };
                    arr1.push(spirit)
                } else {
                    arr1.push(val)
                }
            })
            console.log('arrr1', arr1)
            this.setState({ Content: arr1 })
        }
       
    };

    Close = e => {
      window.location.reload()
    }
    onFormUpdate = e => {

        e.preventDefault();
        console.log("this.state.Identifier jeje", this.state.Identifier, this.state.Title, this.state.Description, this.state.Content, this.state.Files)
        let content = this.state.Content.length > 0 ? this.state.Content : "";
        console.log("contentdata", content)
        const updateForm = {

            Identifier: this.state.Identifier,
            Title: this.state.Title,
            Description: this.state.Description,
            Content: content,
            file: this.state.file,
            files: this.state.Files

        };
        console.log("updateForm___", updateForm);
        this.setState({ status : true })
        // this.props.updateForm(updateForm);
       let data =  this.props.updateLandForm(updateForm)
       console.log('daftasdfasdf', data)
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-form-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Landing</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.Close}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-form">
                                    <div className='row mt-2'>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.Identifier}
                                            id="form-update-id"
                                            type="text"
                                            required
                                            className="d-none" />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="tokenAddresses">Identifier</label>
                                        </div>
                                        <div className="col-md-9 mb-3">
                                            <input
                                                // onChange={this.onChange}
                                                value={this.state.Identifier}
                                                error={errors.identifier}
                                                id="identifier"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.identifier
                                                })}
                                            />
                                            <span className="text-danger">{errors.identifier}</span>
                                        </div>
                                    </div>

                                    {this?.state?.Title && this.state.Identifier != "landing_banner03" && <div className="row mt-2">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="tokenAddresses">Title</label>
                                        </div>
                                        <div className="col-md-9 mb-3">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                error={errors.Title}
                                                id="Title"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}
                                            />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>}

                                    {this?.state?.Description && <div className="row mt-2">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="depositFee">Description</label>
                                        </div>
                                        <div className="col-md-9 mb-3">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this?.state?.Content && this?.state?.Content?.length > 0 && this?.state?.Content?.map((item, ind) =>
                                        <>
                                            {this?.state?.Content[ind]?.Title && <div className="row mt-2">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="depositFee">Title</label>
                                                </div>
                                                <div className="col-md-9 mb-3">
                                                    <textarea
                                                        row="8"
                                                        onChange={(e) => this.onChange1(e, ind)}
                                                        value={this.state.Content[ind].Title}
                                                        // error={errors.Description}
                                                        id="Title"

                                                        className={classnames("form-control", {
                                                            invalid: errors.Description
                                                        })}
                                                    ></textarea>
                                                    <span className="text-danger">{errors.Description}</span>
                                                </div>


                                            </div>}

                                            {this?.state?.Content[ind]?.Description && <div className="row mt-2">
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="depositFee">Description</label>
                                                </div>
                                                <div className="col-md-9 mb-3">
                                                    <textarea
                                                        row="8"
                                                        onChange={(e) => this.onChange1(e, ind)}
                                                        value={this?.state?.Content[ind]?.Description}
                                                        id="Description"

                                                        className={classnames("form-control", {
                                                            invalid: errors.Description
                                                        })}
                                                    ></textarea>
                                                    <span className="text-danger">{errors.Description}</span>
                                                </div>


                                            </div>}

                                            <div className="row mt-2">
                                                {console.log("itemnsadfasdfasd", item)}
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="logoURI">Update Image</label>
                                                </div>


                                                {console.log("this.state.imagepath", this.state.ImageURI, this?.state?.Description)}
                                                <div className="col-md-9 mb-3">
                                                    {console.log("this?.state?.Content[ind]?.ImageURI", this.state.Files, item, this?.state?.Content[ind]?.ImageURI)}
                                                    <div>
                                                        <input
                                                            autoComplete={''}
                                                            onChange={(e) => this.onFileChange(e, ind)}
                                                            value={this?.state?.ImageURI}
                                                            error={errors.logoURI}
                                                            id="logoURI"
                                                            type="file"
                                                            className={classnames("form-control", {
                                                                invalid: errors.logoURI
                                                            })}
                                                        />
                                                        {/* {<label className="custom-file-label" htmlFor="logoURI"> {this?.state?.Files && this?.state?.Files?.length > 0 && item?.ImageURI == ""
                                            ? <>{ this.state.Files.filter((el) => {console.log("el_Data", el.index)})}</> : item?.ImageURI != "" ? item?.ImageURI : "Choose filesss"} </label>} */}
                                                        <span className="text-danger">{errors.logoURI}</span>
                                                    </div>
                                                    {/* {console.log("indexfasdfa", this?.state?.Files, this?.state?.Files?.length > 0, this?.state?.Files[ind]?.ImageURI, item?.ImageURI == "", ind, this?.state?.Files[ind]?.index == ind)} */}
                                                    {this?.state?.Files && this?.state?.Files.length > 0 && item.ImageURI == "" ? this?.state?.Files?.map((val) => {
                                                        return (
                                                            <>{console.log("png_image", val?.index, val?.ImageURI)}{val?.index == ind && <img src={URL.createObjectURL(val?.ImageURI)} className="img-fluid uploads_img mt-3" />}</>
                                                        )
                                                    }) : <> {item?.ImageURI != "" ? <img className="img-fluid uploads_img mt-3" src={backurl + "/Images/" + item?.ImageURI} /> : <></>}</>}
                                                </div>
                                            </div>
                                        </>

                                    )}



                                    {console.log("gthis.sateta", this?.state?.ImageURI, this?.state?.ImageURI != "", this?.state?.ImageURI != undefined, this?.state?.ImageURI != "undefined")}
                                    {(this?.state?.ImageURI != undefined && this?.state?.ImageURI != "") ? <>
                                        {console.log("enter 1111")}

                                        <div className="row mt-2">
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="logoURI">Update Image</label>
                                            </div>
                                            {console.log('this?.state?.ImageURI', this?.state?.ImageURI)}
                                            <div className="col-md-9 mb-3">
                                                <div>
                                                    <input
                                                        autoComplete={''}
                                                        onChange={(e) => this.handleFile(e)}
                                                        type="file"
                                                        value={this?.state?.ImageURI}
                                                        className={classnames("form-control", {
                                                            invalid: errors.logoURI
                                                        })}
                                                    />
                                                    <label className="custom-file-label" htmlFor="logoURI">{this?.state?.file != undefined ? this?.state?.file?.name : this?.state?.ImageURI != "" ? this?.state?.ImageURI : "Choose file"}</label>

                                                    <img className="img-fluid uploads_img mt-3" src={this?.state?.file != undefined ? URL.createObjectURL(this?.state?.file) : this?.state?.ImageURI != "" ? backurl+"/Images/" + this?.state?.ImageURI : <></>} />

                                                </div>
                                            </div>

                                        </div>



                                    </> : <>{console.log("enter 2222")}</>}


                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.Close}>Close</button>
                                <a
                                    form="update-form"

                                    className="btn btn-primary" onClick={(e) => { this.onFormUpdate(e); this.setState({ status: true }) }}>
                                    Update
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormHomeUpdateModal.propTypes = {
    // updateForm: PropTypes.func.isRequired,
    updateLandForm : PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateForm, updateLandForm }
)(withRouter(FormHomeUpdateModal));
