import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

// import { CKEditor } from 'ckeditor4-react';
//Actions

import { IMG_URL } from '../config/env'
import { getCmsEdit, getcmslistData } from '../../actions/cmsAxios';
function EditcmsModal({ show, handleClose, editData, getSettings }) {
    console.log("EditCmscontent", show, editData);

    // const [mapimage, setMapimage] = useState([])
    const [formData, setFormData] = useState({})
    console.log("formsDats", formData);

    const [MyContent, setMyContent] = useState("")
    console.log("MyContent", MyContent);

    useEffect(() => {
        // handleedit()
        setFormData(editData)
        setMyContent(editData?.Content)
        // GetCmsAll()
    }, [])

    const DataContent = async (e) => {
        // console.log("DataContent",e.taget.value);
        try {
            let cmscnt = e.target.value
            if (cmscnt == "") {
                setMyContent(" ")

            }
            else {
                setMyContent(cmscnt)

            }
        } catch (error) {
            console.log("error", error);
        }
    }



    const handleedit = async () => {
        try {

            let payload = {
                id: formData?._id,
                identifier: formData?.identifier,
                Title: formData?.Title,
                Content: MyContent
            }

            let result = await getCmsEdit(payload);
            console.log("result", result)
            if (result?.status == true) {
                toast.success(result?.msg)
                handleClose();
                getSettings()
            }
            else {
                toast.error(result?.msg)

            }
        } catch (error) {
            console.log("error", error);
        }

    }

    const handleChange = async (e) => {
        console.log(e.target.files);
        try {
            console.log("valuess", e.target.value);


            const { id, value } = e.target
            console.log("idvalues", id, value);
            setFormData({ ...formData, ...{ [id]: value } })

        } catch (error) {
            console.log("error", error);
        }


    }


    return (
        <>
            <Modal centered className='cmn_modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Header className='align-items-center px-3 py-2'>
                {/* <div className='cmn_modal_header d-flex justify-content-between align-items-center'> */}
                        <p className='cmn_modal_title mb-0'>Edit CMS</p>
                        <button className='cmn_modal_closer rounded-5 btn text-white pr-0' onClick={handleClose}><i class="fa fa-solid fa-xmark fa fa-times" /></button>
                    {/* </div> */}
                </Modal.Header>

                <Modal.Body>
                    
                    {formData?.identifier == "footercontent"
                        ? <>
                            <div>

                                <div className='rp_singleinput_holder mb-3'>
                                    <p className='rp_label mb-2'>Identifier</p>
                                    <div className='rp_input_holder rounded-2 d-flex justify-content-start align-items-center gap-2'>
                                        <input type='text' placeholder='Identifier' readOnly className='rp_singleInput flex-grow-1 form-control' disabled={true} name='identifier' id='Identifier'
                                            value={formData?.identifier} onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className='rp_singleinput_holder mb-3'>
                                    {MyContent.length <= 0 ?
                                        ""
                                        : <>
                                            <p className='rp_label mb-2'>Content</p>
                                            <textarea rows={5} className='w-100 p-2 textareaback form-control' id="Content" value={MyContent} onChange={(e) => DataContent(e)}></textarea>
                                        </>
                                    }


                                </div>


                                <div className='rp_singleinput_holder mb-4'>

                                </div>




                                <div className='text-end mt-4 pb-3'>
                                    <button className='orange_small_primary' onClick={() => { handleedit() }}>Submit</button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div>

                                <div className='rp_singleinput_holder mb-3'>
                                    <p className='rp_label mb-2'>Identifier</p>
                                    <div className='rp_input_holder rounded-2 d-flex justify-content-start align-items-center gap-2'>
                                        <input type='text' placeholder='Identifier' readOnly className='rp_singleInput flex-grow-1 form-control' disabled={true} name='identifier' id='Identifier'
                                            value={formData?.identifier} onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>


                                <div className='rp_singleinput_holder mb-3'>

                                    <p className='rp_label mb-2'>Title</p>

                                    <div className='rp_input_holder rounded-2 d-flex justify-content-start align-items-center gap-2'>

                                        <input type='text' placeholder='title' className='rp_singleInput flex-grow-1 form-control' name='title' id="Title"
                                            value={formData?.Title} onChange={(e) => handleChange(e)}
                                        />


                                    </div>
                                </div>








                                <div className='rp_singleinput_holder mb-3'>
                                    {MyContent.length <= 0 ?
                                        ""
                                        : <>
                                            <p className='rp_label mb-2'>Content</p>
                                            <textarea rows={5} className='w-100 p-2 textareaback form-control' id="Content" value={MyContent} onChange={(e) => DataContent(e)}></textarea>
                                        </>
                                    }


                                </div>


                                <div className='rp_singleinput_holder mb-4'>

                                </div>




                                <div className='text-end mt-4 pb-3'>
                                    <button className='orange_small_primary btn-theme btn btn-outline-secondary' onClick={() => { handleedit() }}>Submit</button>
                                </div>
                            </div>
                        </>
                    }



                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditcmsModal