import React, { Component, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Navbar from "../../partials/Navbar";
import Sidebar from '../../partials/Sidebar.js';
import bsCustomFileInput from 'bs-custom-file-input';
import { IMG_URL } from '../../config/env'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router-dom";
import { CKEditor } from 'ckeditor4-react';
import { EditRoadmap } from '../../../actions/cmsAxios';


toast.configure();


export function Roadmapedit(props) {

  const history = useHistory();
  const location = useLocation();
  const detail = location.state;

  const initData = {
    "heading": "",
    "description": "",
    "step": ""
  }

  const [formData, setFormData] = useState(initData)
  const [status, setStatus] = useState(false)
  const [ans, setAns] = useState(detail?.description)
  console.log("faqedit", props)
  useEffect(() => {
    bsCustomFileInput.init()

  }, [])


  useEffect(() => {
    setFormData(detail)
  }, [props])


  const onEditorChange = (e) => {
    var content_text = e.editor.getData()
    if (content_text == "") {
      setAns(" ")

    }
    else {
      setAns(content_text)

    }
    //   setFormData({...formData,...{'content' : content_text}})
    //   console.log("fff",formData,{...formData,...{'content' : content_text}})
  }







  const handlechange = async (e) => {

    e.preventDefault();


    const { id, value } = e.target;

    // console.log("files",files)
    setFormData({ ...formData, ...{ [id]: value } })
    // setFormData({...formData,'heading':value})

    console.log("formdata", formData)
  }

  const handleSubmit = async () => {
    var errors = {};

    if (!ans) {
      errors.ans = "description cannot be empty"
      return toast.error("answer cannot be empty")
    }

    if (Object.keys(errors).length == 0) {
      // if(!status){
      console.log("erro length", Object.keys(errors).length, formData)

      var payload = {
        step: formData?.step,
        heading: formData?.heading,
        description: ans,
        id: formData?._id,

      }

      console.log("payload", payload)

      var resp = await EditRoadmap(payload);
      if (resp?.status) {
        toast.success("suucessfully updated")
        setTimeout(() => {
          history.push("/roadmaplist")
        }, 1000);

      }
      else return toast.error(resp.msg)
      // }




    }
  }






  return (
    <div>

            <Navbar />
            <div className="d-flex" id="wrapper">
            <Sidebar/>
                {/* <div className="page-header">
                    <nav aria-label="breadcrumb">
                    </nav>
                </div> */}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                    <div>
                <button className='btn mt-2 allbtn mb-3 btn-primary' type='button' onClick={() => history.goBack()} >Back</button></div>

              <h1 className="mt-2 text-primary">EDIT USER</h1>
              <form className="forms-sample">
                <Form.Group>
                  <label htmlFor="exampleInputName1" className='text-white'>Step</label>
                  <Form.Control type="number" disabled={true} className="form-control" id="step" value={formData.step} onChange={(e) => { handlechange(e) }} />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputName1" className='text-white'>Heading</label>
                  <Form.Control type="text" className="form-control" id="heading" value={formData.heading} onChange={(e) => { handlechange(e) }} />
                </Form.Group>
                {/* <Form.Group>
                    <label htmlFor="date for news">Date</label>
                    <Form.Control type="date" id="date" onChange={(e)=>handlechange(e)}/>
                  </Form.Group> */}
                <Form.Group>
                  <label htmlFor="exampleInputName1" className='text-white'>Description</label>


                  <CKEditor
                    initData={ans}
                    onChange={onEditorChange}
                  />
                </Form.Group>
                {/* {formData?.img && <img className='mb-2' src={status ? URL.createObjectURL(formData?.file) :`${config.ImG}/${formData?.img}`} height={100} width={100}/>} */}

                {/* {formData?.img && <img className='mb-2' src={`${config.ImG}/${formData.img}`} height={100} width={100}/>} */}
                
                {/* <input type="file" id="file" onChange={(e)=>handlechange(e)}/> */}
              </form>
              <button className='btn mt-2 allbtn btn-primary' onClick={() => handleSubmit()}>SUBMIT</button>
                    </div>
                </div>
            </div>

      {/* <div className="row">

        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              

            </div>
          </div>
        </div>

      </div> */}
    </div>
  )

}

export default Roadmapedit;
